<script setup>

  // pinia auth
  import { storeToRefs } from 'pinia';
  import { useAuthStore } from "~/store/auth";
  const authStore = useAuthStore();
  const { authenticated, email } = storeToRefs(authStore); // all reactive stuff here
  const { logout } = authStore; // have all non reactiave stuff here 

  // nyelvi es routing beallitasok
  const localePath = useLocalePath();
  // const { t, locale } = useI18n();
  // const route = useRoute();

  // jelenjen meg a vagy sem?
  let isOpen = ref(false)

  // TODO:
  //  auth.ts használata!

  const userLogout = async () => {
    isOpen.value = false;
    await logout();
    await navigateTo(localePath('/'));
  };

</script>

<template>
  <div v-if="authenticated">
    <div v-show="isOpen" class="absolute inset-0 z-40 h-screen flex opacity-10" @click="isOpen = !isOpen">
      <div class="m-auto"></div>
    </div>
    <div class="relative text-left">
      <div>
        <button
          id="options-menu"
          type="button"
          class="flex items-center px-4 focus:outline-none"
          aria-haspopup="true"
          aria-expanded="true"
          @click="isOpen = !isOpen"
        >
          <span class="text-xl mx-2"
            ><font-awesome-icon :icon="['fas', 'ellipsis-v']"
          /></span>
          <span class="sr-only">{{ $t('Switch currency') }}</span>
        </button>
      </div>
      <!-- megnyilo doboz -->
      <Transition>
        <div
          v-show="isOpen"
          class="origin-top-right absolute right-0 z-50 rounded-md shadow-lg whitespace-nowrap text-boxtxt-dark bg-boxbg-light"
        >
          <div
            class="p-1"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="options-menu"
          >
            <button
              type="button"
              class="mb-1 px-4 py-2 w-60 text-left border-0 bg-none font-light text-sm hover:bg-gray-light hover:bg-opacity-10 rounded"
              @click="userLogout()"
            >
              <span class="mr-2 text-lg"
                ><font-awesome-icon :icon="['fas', 'sign-out-alt']"
              /></span>
              {{ $t('Logout') }}
            </button>
            <div 
              v-if="email"
              class="px-4 py-1 text-center items-center border-0 bg-none font-sans font-light text-xs text-gray-light border-t border-gray-light border-dotted opacity-50"
            >
              {{ email }}
            </div>
          </div>
        </div>
      </Transition>
    </div>
  </div>
</template>
