<script setup>
  /**
   * 
   * Komponens: "kosaram" gomb/link (a menüsorhoz)
   * 
   *  - Mutatja, ha van benne termék.
   * 
  */

  const localePath = useLocalePath();

  // pinia store
  import { storeToRefs } from 'pinia';
  import { useWebshopCartStore } from '@/store/webshopcart';
  const webshopCartStore = useWebshopCartStore();
  const { numberofcartproducts } = storeToRefs(webshopCartStore);
  // const { fetchProductByIdAndAddtToCart } = useWebshopCartStore();
  // const { getNumberOfCartProducts } = webshopCartStore;

</script>

<template>
  <div class="text-left">
    <button
      id="options-menu"
      type="button"
      class="flex items-center focus:outline-none focus:ring-0"
      aria-haspopup="true"
      aria-expanded="true"
    >
      <NuxtLink :to="localePath('my-cart')">
        <div class="text-2xl xs:text-xl mx-6 sm:mx-4 xs:mx-2 relative">
          <div
            v-show="numberofcartproducts > 0"
            class="absolute top-0 right-0 -mt-1 -mr-3 h-5 w-5 rounded-full bg-red-dark opacity-50 animate-ping"
          ></div>
          <div
            v-show="numberofcartproducts > 0"
            class="absolute top-0 right-0 -mt-1 -mr-3 h-5 w-5 rounded-full bg-red-dark text-center text-txt-light font-sans text-xs align-middle"
          >
            {{ numberofcartproducts }}
          </div>
          <font-awesome-icon :icon="['fas', 'shopping-cart']" />
        </div>
        <div class="xs:hidden text-xs">{{ $t('Cart') }}</div>
        <span class="sr-only">{{ $t('Cart') }}</span>
      </NuxtLink>
    </button>
  </div>
</template>
