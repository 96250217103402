<script setup>
  const localePath = useLocalePath();
</script>

<template>
  <div class="text-left">
    <div>
      <button
        id="options-menu"
        type="button"
        class="flex items-center focus:outline-none"
        aria-haspopup="true"
        aria-expanded="true"
      >
        <NuxtLink :to="localePath('my-orders')" class="flex flex-col">
          <div class="text-2xl xs:text-xl mx-6 sm:mx-4 xs:mx-2">
            <font-awesome-icon :icon="['fas', 'user']" />
          </div>
          <div class="xs:hidden text-xs">
            {{ $t('My orders') }}
          </div>
          <div class="sr-only">{{ $t('My orders') }}</div>
        </NuxtLink>
      </button>
    </div>
  </div>
</template>
