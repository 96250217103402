import { defineStore } from 'pinia';

export const useSearchStore = defineStore('search', {
  state: () => ({
    searchText: '', // A keresési szöveg tárolása
  }),
  actions: {
    setSearchText(text) {
      this.searchText = text;
    },
    clearSearchText() {
      this.searchText = '';
    },
  },
})
