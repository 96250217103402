<script setup>

  // Amplify API & GraphQL:
  import { storeToRefs } from 'pinia';
  import { useAuthStore } from "~/store/auth";
  const authStore = useAuthStore();
  const { authenticated } = storeToRefs(authStore); // all reactive stuff here
  const { logout } = authStore; // have all non reactiave stuff here 

  const localePath = useLocalePath();
  // const { locale } = useI18n()

  // variable definitions
  const mobileMenuIsOpen = ref(false)
  const isOpen = ref(false)

  // funcitons
  const toggleMobile = () => {
    isOpen.value = !isOpen.value  
    mobileMenuIsOpen.value = !mobileMenuIsOpen.value
  };

  const userLogout = async () => {
    await logout();
    mobileMenuIsOpen.value = false;
    // await navigateTo(localePath('/'));
  };

</script>

<template>
  <header class="max-w-screen">
    <div
      class="w-full fixed top-0 left-0 z-50 bg-primary shadow-xl"
      style="box-shadow: 10px 0 10px rgba(0, 0, 0, 0.25)"
    >
      <UserGroupAlert />
      <TopBar />
      <div
        class="sm:mx-auto md:mx-auto lg:mx-auto xl:mx-auto md:container lg:container xl:container xs:h-20 sm:h-20 md:h-24 lg:h-24 xl:h-24 flex justify-around"
      >
        <div class="relative px-2 flex lg:px-0 xl:px-0">
          <div class="flex-shrink-0 flex items-center">
            <NuxtLink :to="localePath('/')" class="outline-none"
              ><LazySvgLogoV2 class="w-auto h-20 xs:h-12 sm:h-12"
            /></NuxtLink>
          </div>
        </div>
        <div
          class="hidden md:flex lg:flex xl:flex relative flex-1 px-2 items-center sm:inset-0 invisible lg:visible xl:visible"
        >
          <UiSearchSimple class="w-full md:max-w-md lg:max-w-lg xl:max-w-xl" />
        </div>
        <!-- Gomblista lg és xl méretben, kis felbontásnál elrejtve -->
        <div
          class="xs:hidden sm:hidden md:hidden lg:space-x-2 xl:space-x-5 lg:ml-4 lg:flex xl:flex items-center text-txt-light"
        >
          <NavigationMyOrders />
          <NavigationShoppingBasket />
          <NavigationAccountSwitch />
        </div>
        <!-- Gomblista kis felbontásnál (lg és xl méretnél elrejtve) -->
        <div
          class="w-1/2 lg:hidden xl:hidden flex items-center justify-around text-txt-light"
        >
          <NavigationMyOrders />
          <NavigationShoppingBasket />
          <NavigationMobileMenu :mobileMenuIsOpen="mobileMenuIsOpen" :toggleMobile="toggleMobile" class="text-left" />
        </div>
      </div>
      <!-- Main menu desktop version -->
      <nav
        class="border-none hidden lg:flex lg:space-x-8 xl:flex xl:space-x-8 nav-bar leading-tight"
        aria-label="Global"
      >
        <div
          class="container mx-auto flex justify-between space-x-4 items-center"
        >
          <NuxtLink
            :to="localePath('books')"
            class="py-2 px-3 inline-flex flex-initial transition-colors duration-300 hover:underline outline-none"
          >
            {{ $t('Books') }}
          </NuxtLink>

          <NuxtLink
            :to="localePath('other-languages')"
            class="py-2 px-3 inline-flex flex-initial transition-colors duration-300 hover:underline outline-none"
          >
            {{ $t('Other Languages') }}
          </NuxtLink>

          <NuxtLink
            :to="localePath('newspapers')"
            class="py-2 px-3 inline-flex flex-initial transition-colors duration-300 hover:underline outline-none"
          >
            {{ $t('Newspapers & Magazines') }}
          </NuxtLink>

          <NuxtLink
            :to="localePath('stationary-and-gifts')"
            class="py-2 px-3 inline-flex flex-initial transition-colors duration-300 hover:underline outline-none"
          >
            {{ $t('Stationary & Gifts') }}
          </NuxtLink>

          <!-- class="-mt-2 py-2 px-2 inline-flex flex-initial hover:underline bg-boxbg-light text-txt-dark shadow-lg font-bold" -->
          <NuxtLink
            :to="localePath('on-sale')"
            class="py-2 px-3 inline-flex flex-initial transition-colors duration-300 hover:underline outline-none"
          >
            {{ $t('On Sale') }}
          </NuxtLink>

          <NuxtLink
            :to="localePath('contact-us')"
            class="py-2 px-3 inline-flex flex-initial transition-colors duration-300 hover:underline outline-none"
          >
            {{ $t('Contact us') }}
          </NuxtLink>
        </div>
      </nav>
      <!-- Search bar ONLY on smaller screens -->
      <div class="mt-2 bg-navbg-dark xs:p-1 sm:p-1 md:p-2 lg:hidden xl:hidden">
        <UiSearchSimple />
      </div>
    </div>

    <!-- Main menu mobile version -->
    <nav
      :class="mobileMenuIsOpen ? 'block' : 'hidden'"
      class="mobilemenu lg:container xl:container lg:mx-auto xl:mx-auto xl:hidden lg:hidden"
      aria-label="Global"
    >
      <!-- elérhető méretek: 32 36 40 44 48 -->
      <div
        class="xs:mt-32 sm:mt-36 md:mt-40 px-2 absolute z-40 left-0 top-0 right-0 bottom-0 w-full overflow-y-scroll h-screen shadow-xl bg-page-dark"
      >
        <NuxtLink
          :to="
            localePath({
              name: 'menu-navigation-slug',
              params: {
                slug: localePath('books').substring(localePath('books').lastIndexOf('/') + 1),
              },
            })
          "
          class="block bg-boxbg-dark p-3 my-2 outline-none rounded-md shadow-md"
          @click.native="mobileMenuIsOpen = false"
        >
          {{ $t('Books') }}
        </NuxtLink>
        <!-- :to="localePath('other-languages')" -->
        <NuxtLink
          :to="
            localePath({
              name: 'menu-navigation-slug',
              params: {
                slug: localePath('other-languages').substring(
                  localePath('other-languages').lastIndexOf('/') + 1
                ),
              },
            })
          "
          class="block bg-boxbg-dark p-3 my-2 outline-none rounded-md shadow-md"
          @click.native="mobileMenuIsOpen = false"
        >
          {{ $t('Other Languages') }}
        </NuxtLink>
        <NuxtLink
          :to="localePath('newspapers')"
          class="block bg-boxbg-dark p-3 my-2 outline-none rounded-md shadow-md"
          @click.native="mobileMenuIsOpen = false"
        >
          {{ $t('Newspapers & Magazines') }}
        </NuxtLink>
        <NuxtLink
          :to="
            localePath({
              name: 'menu-navigation-slug',
              params: {
                slug: localePath('stationary-and-gifts').substring(
                  localePath('stationary-and-gifts').lastIndexOf('/') + 1
                ),
              },
            })
          "
          class="block bg-boxbg-dark p-3 my-2 outline-none rounded-md shadow-md"
          @click.native="mobileMenuIsOpen = false"
        >
          {{ $t('Stationary & Gifts') }}
        </NuxtLink>

        <NuxtLink
          :to="localePath('on-sale')"
          class="block bg-boxbg-dark p-3 my-2 outline-none rounded-md shadow-md"
          @click.native="mobileMenuIsOpen = false"
        >
          {{ $t('On Sale') }}
        </NuxtLink>

        <NuxtLink
          :to="localePath('contact-us')"
          class="block bg-boxbg-dark p-3 my-2 outline-none rounded-md shadow-md"
          @click.native="mobileMenuIsOpen = false"
        >
          {{ $t('Contact us') }}
        </NuxtLink>
        <div
          v-if="authenticated"
          role="menuitem"
          class="bg-boxbg-dark p-3 my-2 outline-none rounded-md shadow-md cursor-pointer"
          @click="userLogout()"
        >
          {{ $t('Logout') }}
        </div>
        <SettingsSwitchV3 class="font-light font-sans text-xs" />
      </div>
      <div
        class="fixed bg-boxtxt-dark opacity-75 top-0 left-0 right-0 bottom-0 z-30"
      ></div>
    </nav>
  </header>
</template>

<!--
    scrollbarHandler(allow) {
      // alabbi megtiltja vagy engedi a body es a html elemek fuggoleges scrollozasat
      // amig a modal ablak nyitva van
      const html = document.documentElement
      // const body = document.body
      if (allow === true) {
        // nativ js style valtoztatas: megjelenitjuk ugyan a scrollbart (hogy ne ugraljon a browser szelessege)
        // de nem engedunk gorgetni, kvazi disabled-re rakjuk
        html.style.overflowY = 'scroll'
        html.style.position = 'fixed'
        html.style.inset = '0px'
        // body.classList.add('modal-open') // .css fájlban levő natív css változat
      } else {
        // nativ js style: visszallitjuk a default ertekeket
        html.style.overflowY = 'auto'
        html.style.position = 'static'
        html.style.inset = null
        // body.classList.remove('modal-open') // .css fájlban levő natív css változat
      }
    },

  },
}
</script> -->
