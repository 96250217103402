<script setup>

  /**
   * 
   * Komponens: nyelv és pénznem váltás template
   * (TODO: a funkcionalitásokat ketté szedni külön komponensekbe, és ez a komponens csak a megjelenítés legyen)
   * 
  */

  // pinia stores:
  import { useCurrencyStore } from '@/store/currency'
  import { useCurrencyModalStore } from '@/store/currencymodal';

  const currencyStore = useCurrencyStore()
  const currencyModalStore = useCurrencyModalStore();
  // const closeCurrencyModal = currencyModalStore.closeCurrencyModal();

  // elérhető pénznemek listája:
  const runtimeConfig = useRuntimeConfig();
  const availableCurrencies = runtimeConfig.public.currencies;

  // FIX beállítások:
  // (TODO: később áttenni nuxt config-ba)
  const forceRefresh = true;
  const fixedCurrency = 'HUF';

  // A VeeValidation beállítások frissítése:
  // Nem lesz szükség rá, mert az adott form-nál kell majd helyesen beállítani, nem globálisan.
  // import { setLocale as setVeeValidationLocale } from '@vee-validate/i18n';
  // import { changeValidationLanguage } from '@/plugins/vee-validate'
  // setVeeValidationLocale('hu');

  // nyelvi es routing beallitasok
  // const { locale, locales, setLocale } = useI18n()
  const { setLocale } = useI18n()
  const localePath = useLocalePath();
  const switchLocalePath = useSwitchLocalePath()

  // elérhető nyelvek listája:
  const availableLocales = computed(() => {
    return (locales.value).filter(i => i.code !== locale.value)
  })

  const actualcurrencyjustinfo = computed(() => currencyStore.currencyjustinfo)
  // const actualcurrency = ''; // vuex: currency/currency

  // nyelv átállítása és átirányítás:
  const setLocaleAndForceRefreshInStore = async (locale) => {
    setLocale(locale);
    // switchLocalePath(locale);
    reloadNuxtApp({
      // ttl?: number
      // path: switchLocalePath(locale),
      path: '/',
      force: true,
      persistState: true,
    });
  };

  // átállítja a currency kiírást:
  //  - Pinia store átállítás, és
  //  - megjeleníti a currecy-re vonatkozó felhívást (modal)
  const setCurrencyInStore = (currency) => {
    // currencyModalStore
    currencyModalStore.openCurrencyModal();
    // currencyStore
    currencyStore.setCurrencyJustInfo(currency)
    // console.log('setCurrency to: ', currency);
  };

</script>

<template>
  <div
    class="relative lg:flex xl:flex items-center lg:px-4 xl:px-4 justify-between focus:outline-none text-left"
  >

    <!-- Pénznem átváltása rész -->

    <div
      class="xs:mt-3 sm:mt-3 md:mt-3 xs:pt-2 sm:pt-2 md:pt-2 xs:border-t sm:border-t md:border-t border-dotted border-secondary border-opacity-50"
    >
      <div
        class="flex lg:flex-nowrap xl:flex-nowrap xs:flex-wrap mr-5 items-center"
      >
        <div class="xs:hidden sm:hidden md:hidden">
          <WebshopSettingsCurrencyChargeInfo :webshopFixChargeCurrency="fixedCurrency" />
        </div>

        <div v-if="availableCurrencies" :class="`xs:w-1/${availableCurrencies.length}`" v-for="currency in availableCurrencies" :key="currency.code">
          <a
            href="#"
            class="block p-3 lg:p-0 xl:p-0 m-1 outline-none lg:bg-none xl:bg-none xs:bg-boxbg-dark sm:bg-boxbg-dark md:bg-boxbg-dark rounded-md xs:shadow-md sm:shadow-md md:shadow-md xs:text-center sm:text-center md:text-center"
            :class="
              actualcurrencyjustinfo === currency.code
                ? 'lg:underline xl:underline font-bold'
                : ''
            "
            @click.prevent.stop="setCurrencyInStore(currency.code)"
          >
            <!-- ez a tényleges pénzváltáshoz kellett anno: @click.prevent.stop="setCurrencyAndClearCart(currencySymbol)" -->
            {{ currency.code }} ({{currency.symbol}})
          </a>
        </div>
        <div
          class="xs:w-full lg:hidden xl:hidden xs:text-center sm:text-center flex-1 flex-shrink-0"
        >
          <WebshopSettingsCurrencyChargeInfo :webshopFixChargeCurrency="'HUF'" />
        </div>
      </div>
    </div>
    
    <!-- Nyelv átváltása rész -->

    <div
      class="xs:mt-3 sm:mt-3 md:mt-3 xs:pt-2 sm:pt-2 md:pt-2 xs:border-t sm:border-t md:border-t border-dotted border-secondary border-opacity-50"
    >
      <div class="flex flex-no-wrap">
        <a
          v-if="$i18n.locale != 'hu'"
          href="#"
          class="block lg:p-0 xl:p-0 p-3 m-1 outline-none lg:bg-none xl:bg-none xs:bg-boxbg-dark sm:bg-boxbg-dark md:bg-boxbg-dark rounded-md xs:shadow-md sm:shadow-md md:shadow-md xs:text-center sm:text-center md:text-center"
          @click.prevent.stop="forceRefresh ? setLocaleAndForceRefreshInStore('hu') : setLocale('hu')"
        >
          <SvgFlagHu class="w-5 h-auto inline mr-2" />
          <span class="lg:hidden">váltás magyar nyelvre</span>
        </a>
        <a
          v-if="$i18n.locale != 'en'"
          href="#"
          class="block lg:p-0 xl:p-0 p-3 m-1 outline-none lg:bg-none xl:bg-none xs:bg-boxbg-dark sm:bg-boxbg-dark md:bg-boxbg-dark rounded-md xs:shadow-md sm:shadow-md md:shadow-md xs:text-center sm:text-center md:text-center"
          @click.prevent.stop="forceRefresh ? setLocaleAndForceRefreshInStore('en') : setLocale('en')"
        >
          <SvgFlagEn class="w-5 h-auto inline mr-2" />
          <span class="lg:hidden">switch to english site</span>
        </a>
      </div>
    </div>
  </div>
</template>
