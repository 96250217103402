<script setup>
  /**
   * 
   * Komponens: Menusor feletti vékony sáv
   * 
  */

  // local linkek
  const localePath = useLocalePath()

</script>

<template>
  <div
    class="hidden lg:block xl:block"
    style="background-color: rgb(51, 51, 51)"
  >
    <div
      class="sm:mx-auto md:mx-auto lg:mx-auto xl:mx-auto md:container lg:container xl:container flex items-center justify-between font-light font-sans text-xs py-2"
    >
      <div>
        <NuxtLink :to="localePath('contact-us')" class="px-3">
          <span class="xs:hidden sm:hidden md:hidden">{{ $t('Shop') }}: </span>
          1051 Budapest, Október 6. u. 11.
        </NuxtLink>
        <NuxtLink :to="localePath('contact-us')" class="px-3">
          <span class="xs:hidden sm:hidden md:hidden">Tel: </span>
          +36/1-312-1295
        </NuxtLink>
      </div>
      <div>
        <SettingsSwitchV3 />
      </div>
    </div>
  </div>
</template>
