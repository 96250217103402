export const ProductByEan = /* GraphQL */ `
  query ProductByEan(
    $ean: String!
    $sortDirection: ModelSortDirection
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ProductByEan(
      ean: $ean
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        productTemplate
        createdAt
        updatedAt
        ean
        name
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;