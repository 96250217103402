<script setup>

  /**
   * 
   * Komponens: egyszerű kereső mező
   * 
   *  - EAN esetén egyből a termék adatlapra irányít
   *  - Lapbetöltéskor az URL-ből visszatölti az értéket
   * 
  */

  import { API } from 'aws-amplify'
  // import * as queries from '~/graphql/queries'
  import * as querySearchByEan from '~/graphql/query-quick-search-by-ean'
  import { useScrollStore } from '@/store/scroll';
  import { useSearchStore } from '@/store/search'; // Importáljuk a Pinia store-t

  const scrollStore = useScrollStore();
  const searchStore = useSearchStore();

  // nyelvi es routing beallitasok
  const localePath = useLocalePath();
  // const { locale } = useI18n();
  const route = useRoute();
  const router = useRouter();

  const loading = ref(false);
  const searchinfo = ref('');
  // const searchtext = ref('');

  // A keresési szöveg helyett a Pinia store-ból töltjük vissza
  const searchtext = computed({
    get: () => searchStore.searchText, // A keresési szöveg a store-ból
    set: (value) => searchStore.setSearchText(value), // Frissítjük a store-ban a keresési szöveget
  });

  // URL search string betöltése a route-ból
  // onMounted(() => {
  //   if (route.query.s) { // Ha van "s" paraméter az URL-ben
  //     searchStore.setSearchText(decodeURIComponent(route.query.s)); // A keresési szöveg beállítása a store-ban
  //   } else if (!route.fullPath.includes('/search')) { // Ha nincs "s" paraméter, de nem a keresési oldalon vagyunk
  //     searchStore.clearSearchText(); // Csak akkor ürítjük, ha nem a keresési oldalon vagyunk
  //   }
  // });
  // Watch használata a route.query.s figyelésére
  watch(
    () => route.query.s, // Figyeljük az URL "s" paraméterének változását
    (newVal) => {
      if (newVal) { // Ha van "s" paraméter, beállítjuk a keresési szöveget
        searchStore.setSearchText(decodeURIComponent(newVal));
      } else { // Ha nincs "s" paraméter, töröljük a keresési szöveget
        searchStore.clearSearchText();
      }
    },
    { immediate: true } // Azonnal lefuttatja a watch-ot az inicializáláskor is
  );
  
  // is it an EAN13 number or not?
  const checkEan = (val) => {
    if (val.length === 13) {
      return /^\d+$/.test(val)
    }
    return false
  };

// Keresés EAN-ra
const searchByEan = async () => {
  loading.value = true
  try {
    const wildcardtext = searchtext.value.trim()
    // Új: Ellenőrizzük, hogy a wildcardtext nem üres-e
    if (!wildcardtext) {
      throw new Error("A keresési szöveg üres.");
    }

    const dataholder = await API.graphql({
      query: querySearchByEan.ProductByEan,
      variables: { 
        deleted: {
          ne: true,
        },
        ean: wildcardtext,
      },
      authMode: 'AWS_IAM',
    })
    const searchitemsbyean = dataholder.data.ProductByEan.items;
    const numberofitemsbyean = searchitemsbyean.length || 0;
    const localSearchPath = localePath('/search');
    if (numberofitemsbyean === 1) {
      let localbookpath = localePath({
        name: 'book-slug',
        params: { slug: searchitemsbyean[0].id },
      })
      if (searchitemsbyean[0].productTemplate !== 'BOOK') {
        localbookpath = localePath({
          name: 'stationary-and-gift-slug',
          params: { slug: searchitemsbyean[0].id },
        })
      }
      // Új: Ellenőrizzük, hogy a jelenlegi útvonal nem egyezik-e meg a cél útvonallal
      if (route.name !== localbookpath) {
        router.push({ path: localbookpath })
      }
    } else {
      // Új: Ellenőrizzük, hogy a jelenlegi útvonal nem egyezik-e meg a cél útvonallal
      // Az új keresés előtt töröljük a `p` paramétert az URL-ből
      const newQuery = { ...route.query };
      delete newQuery.p;
      if (route.name !== localSearchPath) {
        router.push({
          path: localSearchPath,
          query: {
            ...newQuery,
            stype: 'name',
            s: encodeURIComponent(wildcardtext),
          },
        })
      }
    }
  } catch (error) {
    console.error(error);
    // Új: Hiba esetén értesítjük a felhasználót
    alert("Hiba történt a keresés során. Kérjük, próbálja meg újra.");
    // useBugsnag().notify(new Error(error))
  } finally {
    loading.value = false
  }  
};

const goToSearchPageAndSearch = async (stype) => {
  searchtext.value = searchtext.value.trim();
  if (searchtext.value === '' || searchtext.value.length < 2) {
    return;
  }
  
  // ISBN esetén más feladatok:
  if (checkEan(searchtext.value) === true) {
    searchByEan();
  } else {
    const localSearchPath = localePath('/search');

    const newQuery = { ...route.query };
    delete newQuery.p; // Töröljük a `p` paramétert
    
    // Keresés gombra kattintás előtt beállítjuk a query paramétert az URL-ben
    await router.replace({
      path: route.path,
      query: {
        ...newQuery,
        s: encodeURIComponent(searchtext.value), // Az URL-ben már most beállítjuk a keresési szöveget
      }
    });

    // Oldal tetejére ugrás a navigálás előtt
    scrollStore.setScrollPosition(0);

    await navigateTo({
      path: localSearchPath,
      query: {
        ...newQuery,
        stype,
        s: encodeURIComponent(searchtext.value), // A keresési oldalra való navigáláskor is megadjuk az URL-ben
      },
    });

    // // Megvárjuk a DOM renderelését, majd görgetünk a megfelelő pozícióra
    // await nextTick(); // Megvárjuk, hogy a DOM teljesen renderelődjön
    // // Header elem belső első div-jének magasságának lekérése
    // const headerDiv = document.querySelector('header div'); // A header első div elemének kiválasztása
    // const headerDivHeight = headerDiv ? headerDiv.offsetHeight : 0; // Ha van header div, akkor annak a magasságát levonjuk
    // Scroll pozíció beállítása figyelembe véve a header div magasságát
    // scrollStore.setScrollPosition(headerDivHeight); // A tetejére görgetés, a fejléc méretének figyelembevételével
 
  }
};

</script>

<template>
  <div>
    <div class="relative mx-auto text-gray-600 z-40">
      <input
        v-model="searchtext"
        class="block w-full bg-boxbg-light h-10 px-5 pr-16 rounded-t-md rounded-md text-md focus:outline-none text-txt-dark placeholder-txt-dark placeholder-opacity-50 font-light font-sans"
        type="search"
        name="search"
        autofocus
        autocomplete="off"
        :placeholder="$t('Search')"
        @keyup.enter="goToSearchPageAndSearch('name')"
      />
      <div
        class="flex flex-no-wrap justify-between absolute right-0 top-0 mt-1 mr-1"
      >
        <div
          v-show="searchinfo !== ''"
          class="mt-2 mr-1 w-30 h-8 text-gray text-xs font-sans font-light select-none"
        >
          <span class="text-gray text-md mt-2 mx-1 opacity-50"
            ><font-awesome-icon :icon="['fas', 'life-ring']"
          /></span>
          {{ searchinfo }}
        </div>
        <button
          type="submit"
          class="w-10 h-8 text-center bg-navbgsecondary-dark rounded-md transition-colors duration-300 hover:text-txt-light focus:outline-none"
          :class="searchtext === '' ? 'disabled' : ''"
          @click="goToSearchPageAndSearch('name')"
        >
          <font-awesome-icon
            :icon="['fas', 'search']"
            class="h-4 w-4 fill-current"
          />
        </button>
      </div>
    </div>
  </div>
</template>
